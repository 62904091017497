import ClassroomService from '../services/classroom.service';

const initialState = {};

export const classroom = {
  namespaced: true,
  state: initialState,

  actions: {
    list({ commit }) {
      return ClassroomService.list().then(
        (data) => {
          commit('classroomListSuccess', data);
          return Promise.resolve(data);
        },
        (error) => {
          commit('classroomListFailure');
          return Promise.reject(error);
        }
      );
    },
  },

  mutations: {
    classroomListSuccess(state, classrooms) {
      let cachedClassrooms = [];
      classrooms.sort((a, b) => a.fullName.localeCompare(b.fullName));

      if (classrooms.length === 0) {
        const cached = JSON.parse(sessionStorage.getItem('last-classrooms'));
        if (cached) {
          cachedClassrooms = cached;
        }
      } else {
        sessionStorage.setItem('last-classrooms', JSON.stringify(classrooms));
        cachedClassrooms = classrooms;
      }

      state.classrooms = cachedClassrooms;
    },

    classroomListFailure(state) {
      state.classrooms = null;
    },
  },
};
