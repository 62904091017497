<template>
  <nav class="menu">
    <router-link to="/" class="nav-link">
      <img :src="zeusLogo" alt="Menu logo" />
    </router-link>
    <div class="menu-entry">
      <router-link to="/product" class="nav-link product-route">
        <img :src="productBox" />
        <p>{{ $t('products') }}</p>
      </router-link>

      <div id="submenu" class="submenu">
        <!--
        <a id="offer-route" class="nav-link offer-route pointer">
          <img :src="label" />
          <p>{{ $t('offer') }}</p>
        </a>
        -->
        <router-link to="/product/sales" class="nav-link offer-sales">
          <img :src="target" />
          <p>{{ $t('sales') }}</p>
        </router-link>
      </div>
    </div>
  </nav>
</template>

<script>
export default {
  data() {
    return {
      zeusLogo: require('../assets/images/zeus-white.svg'),
      productBox: require('../assets/images/product-box-pink.svg'),
      label: require('../assets/images/label.svg'),
      target: require('../assets/images/target.svg'),
    };
  },
};
</script>

<style lang="scss" scoped>
@import '../scss/variables.scss';

.menu {
  width: 130px;
  background-color: $blue-zeus;
  font-weight: bold;
  font-size: 16px;
  position: fixed;
  left: 0;
  display: flex;
  flex-direction: column;
  height: 100vh;
  top: 0;

  img {
    margin: 20px 4px auto;
  }
}

div.menu-entry {
  margin-top: 2em;

  .submenu {
    margin-top: 15px;

    color: $neutral-00;
    p {
      text-align: center;
    }

    a {
      padding-top: 18px;
      height: 75px;
      border-radius: 4px;
    }

    .offer-route.offer-route-active {
      background-color: $warning;
      width: 90px;
      margin-left: auto;
      margin-right: auto;
    }
  }

  a {
    color: $neutral-00;
    padding-top: 30px;
  }

  img {
    margin: 0 auto;
    display: block;
    margin-bottom: 5px;
  }

  .product-route {
    background-color: $neutral-00;
    width: 130px;
    text-align: center;

    p,
    svg {
      color: $pink;
    }

    img {
      height: 30px;
      margin-top: 0;
    }
  }
}
</style>
